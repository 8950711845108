<template>
  <Header :nav="3" />
  <div class="banner" />
  <div class="c_body">
    <div class="profile"></div>
    <div class="news_frame">
      <div
        class="news_line"
        v-for="(news, index) in news_list"
        :key="index"
        @click="go_detail(news.id)"
      >
        <div class="under_line">
          <p class="time">{{ convertTime(news.publish_at, "YYYY-MM-DD") }}</p>
          <p class="title">{{ news.title }}</p>
        </div>
        <p class="arrow">>>></p>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import { useRouter } from "vue-router";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { GET_NEWS } from "../http/api";
import moment from "moment";

export default {
  setup() {
    const router = useRouter();
    const jump_page = (page_name, value) => {
      router.push({
        name: page_name,
        params: {
          id: value,
        },
      });
    };
    return {
      jump_page,
    };
  },
  name: "About",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      news_list: [],
    };
  },
  methods: {
    go_detail(id) {
      this.jump_page("Detail", id);
    },
    convertTime(timeStr, formatStr) {
      return moment(timeStr).format(formatStr);
    },
  },
  created: function () {},
  mounted: function () {
    GET_NEWS("page_size=500").then((res) => {
      this.news_list = res.results;
      this.total = res.count;
    });
  },
};
</script>

<style scoped lang="scss">
.banner {
  width: 100%;
  height: 260px;
  background: url("../assets/banner5.png") center center no-repeat;
  background-size: cover;
}
.c_body {
  width: 858px;
  margin: 0 auto;
  text-align: left;
  .profile {
    position: relative;
    width: 100%;
    height: 180px;
    background: url("../assets/banner_news.png") center center no-repeat;
    background-size: contain;
  }
  .news_frame {
    width: 100%;
    padding-top: 75px;
    .news_line {
      width: 100%;
      height: 24px;
      padding-left: 45px;
      margin-bottom: 36px;
      background: url("../assets/51.png") 15px center no-repeat;
      background-size: contain;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      .under_line {
        width: fit-content;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        height: 24px;
        border-bottom: 1px #c31920 solid;
        display: inline-block;
        .time {
          font-size: 20px;
          color: #c31920;
          line-height: 20px;
          float: left;
        }
        .title {
          max-width: 32em;
          font-size: 20px;
          color: #c31920;
          line-height: 20px;
          padding-left: 20px;
          word-break: keep-all;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          float: left;
        }
      }
      .arrow {
        font-size: 20px;
        color: #c31920;
        line-height: 20px;
        float: right;
      }
    }
  }
}
</style>
